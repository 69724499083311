import React from 'react'
import { jitsi_DOMAIN } from '../utils/applicationConstants'
import  './meetingpopupcss.css';
import RoomService from './RoomService'

function MeetingPopup (props) {

    //   let finalUrl='';
     
    //  if(props.meetingId!==undefined){
     
    //   finalUrl=`${jitsi_URL + "/" + props.meetingId}`;
    //   console.log("In If +=>> " + finalUrl)
    
    //  }else{
       
    //   finalUrl=props.meetingUrl;
    //   console.log("In Else +=>> " + finalUrl)
    //  }

    if(props.isJoined) {

      var container = document.querySelector(`#frameId${props.id}`);
      
      var api = null;
      
      var token = props.jwtToken ? props.jwtToken : '';

      var options = {

        roomName: props.meetingId,

        userInfo: {
            displayName: props.participantname
        },
        
        parentNode: container,
            
        width: "100%",
            
        height: "100%",
        
        enableNoAudioDetection: true,
        
        jwt: token,
    
        enableNoisyMicDetection: true,
    
        startAudioOnly: true,
    
        startAudioMuted: 10,
    
        startWithVideoMuted: true,
    
        desktopSharingChromeExtId: null,
    
        desktopSharingChromeSources: [ 'screen', 'window', 'tab' ],
    
        desktopSharingChromeMinExtVersion: '0.1',
    
        autoCaptionOnRecord: true,
    
        enableWelcomePage: true,
    
        enableUserRolesBasedOnToken: true
      
        };
      
      api = new window.JitsiMeetExternalAPI(jitsi_DOMAIN, options);

      api.addEventListener('videoConferenceLeft', function(event) {
        if(props.jwtToken!==undefined){
          RoomService.saveRoomHistory(props.meetingId,"LEFT").then(()=>{
  
          })
      }
      
      });

      api.addEventListener('videoConferenceJoined', function(event) {
       
         if(props.jwtToken!==undefined){
          RoomService.saveRoomHistory(props.meetingId,"JOINED").then(()=>{
  
          })
         }
      
      });

      window.addEventListener('beforeunload', function (e) {
      
        e.preventDefault();
        if(props.jwtToken!==undefined){
        RoomService.saveRoomHistory(props.meetingId,"LEFT").then(()=>{
  
        })
        }
       // e.returnValue = '';
    });

      if(props.password) {
        api.addEventListener('participantRoleChanged', function(event) {
          if (event.role === "moderator") {
              api.executeCommand('password', props.password);
              // RoomService.saveRoomHistory(props.meetingId,"JOINED").then(()=>{
  
              // })
          }
        });
     
        // join a protected channel
        api.on('passwordRequired', function ()
        {
          api.executeCommand('password', props.password);
        });
      }
    }

        return(
         

            <div className="modal fade" id={"MeetingPopup"+props.id} tabIndex="-1" 
            role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{margin: "0px", padding: "0px"}}>
  <div className="modal-dialog modal-dialog-meetingpopup" role="document">
            <div className="modal-content modal-content-meetingpopup">
               <div className="modal-header"  style={{margin: "0px", padding: "0px" , height:"35px"}}>
                  <div className="col-4">
                  </div>
                  <div className="col-4 bg-primary text-light" style={{height: "100%"}}>
                    <h5 className="col-12 modal-title text-center">Video Conference System</h5>
                  </div>
                  <div className="col-4">
                  <button type="button" className="close"  style={{marginTop : "-10px"}} data-dismiss="modal" onClick={() => { window.location.reload()}}>&times;</button>
                  </div>
               </div>
               <div className="modal-body" style={{margin: "0px", padding: "0px"}}>
              
               {/*props.isJoined &&  <Iframe url={finalUrl} id="frameId"
           frameborder="0" 
           margin="0" 
           padding="0" 
           width="100%" 
           height="100%" 
           position="absolute"
           scrolling="auto"/>
          */}

          {<div className="holds-the-iframe"
            id={"frameId"+props.id} 
            style={{margin: "0px", padding: "0px", position: "absolute", width: "100%", height: "100%"}}> 
          </div>}

           </div>
             </div>
             </div> 
        </div>
        )
    }

export default MeetingPopup
