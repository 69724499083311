import React, { useState } from 'react'
import RoomService from './RoomService';
import validator from 'validator'


function AddRoom () {
        const[roomName, setRoomName]= useState('');
        const[password, setpassword]= useState('');
        const[roomErrorMessage,setRoomErrorMessage] = useState('');
        const[passwordErrorMessage,setPasswordErrorMessage] = useState('');
        const[buutonStatus, setButtonStatus]= useState('Create meeting');
      
        // const[showSuccessMessage, setSuccessMessage]= useState(false);
        const[showFailureMessage, setFailureMessage]= useState('');
    
    function reset(){
      setRoomName("");
      setRoomErrorMessage("");
      setFailureMessage("");
      setpassword("");
      setPasswordErrorMessage("")
    }

    function addRoomClicked() {
     
      let flag=false;
      
      if(validator.isEmpty(roomName)){
        setRoomErrorMessage('Please enter meeting name');
      }

      if(validator.isEmpty(password)){
        setPasswordErrorMessage('Please enter password');
      }

      if(validator.isEmpty(roomName) || validator.isEmpty(password)){
        flag=false
      }else{
        flag=true
      }

      if(flag){

        var pattern = new RegExp("^(?:[a-zA-Z0-9_]*)?$");
        if(pattern.test(roomName)){
          setButtonStatus("Please Wait...");
          RoomService
              .executeAddRoomService(roomName,password)
              .then((response) => {
                  console.log("Response:: " + response.data)
                  // setSuccessMessage(true);
                  setFailureMessage(false);
                  setButtonStatus("Create meeting");
                  window.location.href = `/Meetings`
              }).catch((error) => {
                if(error.response) {
                  console.log("Response Type :: " + error.response.status)
                  if(error.response.status === 409) {
                    setFailureMessage(roomName  + ' Meeting already taken.');
                  }
                  if(error.response.status === 401) {
                    window.location.href = "/logout";
                  }
                } else {
                  console.log("Error :: " + error)
                  setFailureMessage(roomName  + ' Meeting not created please try later.');
                }
                // setSuccessMessage(false);
                setButtonStatus("Create meeting");
              })
        }else{
          
          setRoomErrorMessage('Enter valid meeting name');
        }
      }    
    }
        return(

            <div className="modal fade" id="addRoom" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Create meeting</h5>
        <button type="button" className="close" data-dismiss="modal" onClick={reset} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="input-group">
                                            <input type="text" className="form-control" 
                                                placeholder="Enter meeting name" autoComplete="roomId"
                                                name="roomId"
                                                value={roomName} 
                                                onChange={(e)=>{setRoomName(e.target.value.trim());setRoomErrorMessage("");setPasswordErrorMessage("");setFailureMessage("")}}  
                                             />
                                        </div>
                                        {roomErrorMessage.length > 0 && <span  className="text-danger">{roomErrorMessage}</span>}
                                
                                    <div>
                                    <div className="input-group mt-3">
                                            <input type="password" className="form-control" 
                                                placeholder="Password" autoComplete="current-password"
                                                name="password" 
                                                value={password}
                                                onChange={(e)=>{setpassword(e.target.value);setRoomErrorMessage("");setPasswordErrorMessage("");setFailureMessage("")}}
                                                />
                                    </div>
                                    {passwordErrorMessage.length > 0 && <span  className="text-danger">{passwordErrorMessage}</span>}
                                    { showFailureMessage &&
                                            <div className="alert alert-danger mt-3" role="alert">
                                                {showFailureMessage} 
                                            </div>
                                    }
                                    </div>
      </div>
      <div className="modal-footer mt-4">
        <button type="button" className="btn btn-secondary" onClick={reset} data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={addRoomClicked}>{buutonStatus}</button>
      </div>
    </div>
  </div>
</div>
        )
    }

export default AddRoom