import React, { useState } from 'react'
//import { jitsi_URL } from '../utils/applicationConstants'
import RoomService from './RoomService';
import $ from 'jquery';
import MeetingPopup from '../room/meetingpopup';
import AuthenticationService from '../authentication/AuthenticationService';

function JoinRoom () {
        const[roomErrorMessage,setRoomErrorMessage] = useState('');
        const[roomName, setRoomName]= useState('');
        const[password, setPassword]= useState('');
        const[isJoinedClicked, setIsJoinedClicked ] = useState(false);
        const[passwordErrorMessage,setPasswordErrorMessage] = useState('');
        
    function joinRoomClicked() {

      if(!password){
        setPasswordErrorMessage('Enter password')
      }

      if(roomName && password) {
        var pattern = new RegExp("^(?:[a-zA-Z0-9_]*)?$");
        if(pattern.test(roomName)){
          RoomService.executeJoinRoomAuthenticationService(roomName,password)
          .then((response) => {
              if(response.data!==''){
               
                RoomService
                .updateParticipantCount(AuthenticationService.getFirstNameLastName(),roomName)
                .then(() => {
                })   
                
                $('#joinRoom').modal('hide'); 
                setIsJoinedClicked(true)
                $('#MeetingPopup2').modal('show');
                //window.open(`${jitsi_URL}/${roomName.trim()}`,'_self');
             }else{
               setRoomErrorMessage('Meeting does not exist');
             }
            
          }).catch((error) => {
             if(error.response.status===401){
               setPasswordErrorMessage("Password is incorrect.")
             }
          })

        }else{
          setRoomErrorMessage('Enter valid meeting name');
        }
      }else{
        if(!roomName)setRoomErrorMessage('Enter meeting name');
      }
      
    }

    function resetField(){
      setRoomName("");
      setRoomErrorMessage("");
      setPasswordErrorMessage("");
      setPassword("");

    }

        return(
          <div>
            <div className="modal fade" id="joinRoom" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Join Meeting</h5>
        <button type="button" className="close" data-dismiss="modal" onClick={resetField} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="input-group">
                                            <input type="text" className="form-control" 
                                                placeholder="Enter meeting name" autoComplete="roomId"
                                                name="roomId"
                                                value={roomName} 
                                                onChange={(e)=>{setRoomName(e.target.value.trim()); setRoomErrorMessage('') ; setPasswordErrorMessage('')}}   
                                             />
                                        </div>
                                        {roomErrorMessage.length > 0 && <span  className="text-danger">{roomErrorMessage}</span>}
                                        <div className="input-group mt-3">
                                            
                                            <input type="password" className="form-control" 
                                                placeholder="Enter Password" autoComplete="current-password"
                                                name="password" 
                                                value={password}
                                                onChange={(e)=>{setPassword(e.target.value.trim());setRoomErrorMessage('') ;setPasswordErrorMessage('')}} 
                                                />
                                        </div>
                                        {passwordErrorMessage.length > 0 && <span  className="text-danger">{passwordErrorMessage}</span>}
                                    <div>
                                    </div>
      </div>

     

      <div className="modal-footer  mt-3">
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={resetField}>Close</button>
        <button type="button" className="btn btn-primary" onClick={joinRoomClicked}>Join meeting</button>
      </div>
    </div>
  </div> 
</div>
<MeetingPopup id="2" meetingId={roomName} isJoined={isJoinedClicked} password={password} participantname={AuthenticationService.getFirstNameLastName()}/>
  
  </div>


        )
    }

export default JoinRoom