import React, { Component } from 'react';
import AuthenticationService from '../authentication/AuthenticationService'
import 'bootstrap/dist/css/bootstrap.css';
import JoinRoomAuthenticationPage from '../room/joinRoomAuthenticationPage'
import { parse } from "query-string"
import * as applicationConstants from '../utils/applicationConstants';
//const searchParams = parse(location.search)

// export const location = () => {
//     const location = useLocation();
//   };

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            hasLoginFailed: false,
            isLoginDisable: false,
            buttonValue: "Login",
            emailValidationMessaage: '',
            passwordValidationMessage: '',
            meetingId: parse(window.location.search).meetingId ? parse(window.location.search).meetingId : '',
            roleId : ''
        }
  
        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
    }

    handleChange(event) {
        this.setState({passwordValidationMessage : ''})
        this.setState({hasLoginFailed : false})
       
        if(event.target.name === 'email') {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(event.target.value)) {
              this.setState({ emailValidationMessaage: 'Please enter valid email address.' })
              //this.setState({ isLoginDisable: true})
            } else {
                this.setState({ emailValidationMessaage: ''})
                //this.setState({ isLoginDisable: false})
            }
        }
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

    loginClicked() {
        this.setState({ emailValidationMessaage: '' })
        this.setState({ passwordValidationMessage: '' })

        if(!this.state.email || this.state.email==='') {
            this.setState({ emailValidationMessaage: 'Please Enter Email' })
        } else if(!this.state.password || this.state.password==='') {
            this.setState({ passwordValidationMessage: 'Please Enter Password' })
        } else {
            this.setState( { isLoginDisable: true })
            this.setState( { buttonValue: 'Please Wait...'})

            AuthenticationService
            .executeOAuthAuthenticationService(this.state.email, this.state.password)
            .then((response) => {
                if(response.data.accessToken!==undefined){
                   // this.setState({roleId : response.data.userRoles[0].role.id})
                    console.log("Response:: " + response.data.accessToken)
                    AuthenticationService.registerSuccessfulLoginForOAuth(response.data.firstName,response.data.lastName, this.state.email, response.data.accessToken)
                    //{<Redirect to='/welcome' />}
                    localStorage.setItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME, response.data.userRoles[0].role.id)
                    
                    if(response.data.userRoles[0].role.id===1){
                        window.location.href = `/Admin`;
                    }else{
                        window.location.href = `/Meetings`;
                    }
                        
                //    {
                //     return(
                //     <Welcome data={response.data.userRoles[0].role.id}/>
                //     );}
                  
                }else{
                    this.setState({ showSuccessMessage: false })
                    this.setState({ hasLoginFailed: true })
                    this.setState( { isLoginDisable: false })
                    this.setState( { buttonValue: 'Login'})
                }

            }).catch((error) => {
              if(error.response.status === 401) {
                  window.location.href = "/logout";
              }
            })
        }
    }

    render() {

        // if(AuthenticationService.isUserLoggedIn()) {
        //     if(localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME)===1){
        //         window.location.href = '/admindashboard'
        //     }else{
        //         window.location.href = '/welcome'
        //     }
        //     // /* <Redirect to='/welcome' /> */
        //     // window.location.href = '/welcome'
        // }
        return(
            <div>
            <main className="main d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="card-group" style={{marginTop: "3%"}}>
                                <div className="card">
                                    {/* <div className="card-header bg-primary text-light">
                                        <h3>Video Conference System</h3>
                                    </div> */}
                                    <div className="card-body">
                                        {this.state.hasLoginFailed &&
                                            <div className="alert alert-danger" role="alert">
                                                Invalid email or password
                                            </div>
                                        }
                                        <p className="text-muted"><b>Registered User Sign In</b></p>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="email" className="form-control" 
                                                placeholder="Email" autoComplete="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange} />
                                        </div>
                                        <p style={{color: 'red'}}>{this.state.emailValidationMessaage}</p>
                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-lock"></i></span>
                                            </div>
                                            <input type="password" className="form-control" 
                                                placeholder="Password" autoComplete="current-password"
                                                name="password" 
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                onKeyPress={(event) => {
                                                    var code = event.keyCode || event.which;
                                                    if(code === 13) {
                                                        document.getElementById('btnLogin').click();
                                                    } 
                                                }}
                                                />
                                        </div>
                                        <p style={{color: 'red'}}>{this.state.passwordValidationMessage}</p>
                                        <div className="row mt-4">
                                            <div className="col-6">
                                                <button type="submit" className="btn btn-primary px-4" 
                                                    id="btnLogin"
                                                    onClick={this.loginClicked}
                                                     disabled={this.state.isLoginDisable}
                                                >
                                                    {this.state.buttonValue}
                                                </button>
                                            </div>
                                        </div>

                                        <a href="/forgotPassword" className="row mt-4" style={{marginLeft:"1px"}}>Forgot Password? </a>
                                        {/* {<small>New to  Video Conference System? <a href="/registration">Sign up for free</a></small>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <JoinRoomAuthenticationPage meetingId={this.state.meetingId} />
            </div>  
        )
    }
}

export default Login