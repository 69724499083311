import React from 'react'

import Header from '../header/header';

const Home = (props) => {
    return(
        <div id="mainDiv">
            <Header />
            <main role="main" className="inner cover">
                {props.children}
            </main>
    </div>
    )
}

export default Home