import React, { Component } from 'react';
 import { MDBDataTable } from 'mdbreact';
import "@fortawesome/fontawesome-free/css/all.min.css";
import $ from 'jquery'
import AuthenticationService from '../authentication/AuthenticationService';
import Registration from '../registration/registrationPage';
import RegistrationService from '../registration/RegistrationSevice'
import  './userlist.css';
import './custommdb.css';
import validator from 'validator'
import * as applicationConstants from '../utils/applicationConstants'

  class  UserList extends Component {      
    
    constructor(props) {
      super(props);
      this.state = {
        userList : [],
        emailId : '',
        passwordErrorMessage : '',
        password : '',
        userListData : [],
        data : {
          columns: [],
          rows : []
        },
        firstname : '',
        lastname : '',
        useremail : '',
        firstName : '',
        lastName : '',
        errors: {
          editEmail: '',
          editfirstname: '',
          editlastname: '',
          editdesignation : ''
        }
      }
      this.componentWillMount = this.componentWillMount.bind(this)
      this.copyMeetingClicked = this.copyMeetingClicked.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.handleChangeOnUpdate = this.handleChangeOnUpdate.bind(this)
      this.saveButtonClicked = this.saveButtonClicked.bind(this)
      this.resetValues = this.resetValues.bind(this);
      this.removeUserClicked = this.removeUserClicked.bind(this)
      this.editUserClicked = this.editUserClicked.bind(this)
      this.updateUserData = this.updateUserData.bind(this)
      this.escFunction = this.escFunction.bind(this);

    }

    escFunction(event){
      if(event.keyCode === 27) {
        this.resetValues();
        //  $("#editemailspan").html("")
        //  $("#editfirstnamespan").html("")
        //  $("#editlastnamespan").html("")
        //  $("#editdesignationspan").html("")
        //  this.setState({passwordErrorMessage : ''})
      }
  }

  componentDidMount(){
    //  $("input[type='text']").wrap("<form>");
    //  $("input[type='text']").closest("form").attr("autocomplete","off");
 
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }
  
    async componentWillMount(){
      var array1 = [1, 2, 3];
      console.log(array1.includes(4)); 
      console.log(array1.length); 
      AuthenticationService.getUserList()
      .then(
          (response) => {
            
            for (var key in response.data) {
              if (response.data.hasOwnProperty(key)) {
               
               // alert(JSON.stringify(response.data[key].userRoles[0].role.name))
                this.state.userListData.push({firstname: response.data[key].firstName,lastname:response.data[key].lastName,email:response.data[key].email,
                  usertype : response.data[key].userRoles[0].role.name,
                  action:<div><button type="button" name={response.data[key].email} 
                  className="btn icon " onClick={this.copyMeetingClicked}>
                    <span><i className="fa fa-key text-gray-300 " title="Reset Password"></i></span></button>
                    <button type="button" name={response.data[key].email} 
                    className="btn icon" onClick={this.editUserClicked}>
                    <span><i className="fa fa-edit text-gray-300" title="Edit User"></i></span></button>
                    <button type="button" name={response.data[key].email} 
                    className="btn icon " onClick={this.removeUserClicked}>
                    <span><i className="icon-remove text-gray-300" title="Remove User"></i></span></button>
                    </div>},)
              }
            }


            this.setState({
              data : {
                columns: [
                  {
                    label: "First Name",
                    field: "firstname",
                    width: 150
                  },
                  {
                    label: "Last Name",
                    field: "lastname",
                    width: 200
                  },
                  {
                    label: "Email",
                    field: "email",
                    width: 100
                  },
                  {
                    label: "User Type",
                    field: "usertype",
                    width: 100
                  },
                  {
                    label: "Action",
                    field: "action",
                    width: 150
                  },
                ],
              rows: this.state.userListData
            }
            })

      }
      ).catch(
          (error) => {
              console.log("Error :: " + error);
              if(error.response.status === 401) {

                window.location.href = "/logout";
            }
          }
      )

      // await setTimeout(function afterTwoSeconds() {
      //   this.setState({
      //     data : {
      //       columns: [
      //         {
      //           label: "First Name",
      //           field: "firstname",
      //           width: 150
      //         },
      //         {
      //           label: "Last Name",
      //           field: "lastname",
      //           width: 200
      //         },
      //         {
      //           label: "Email",
      //           field: "email",
      //           width: 100
      //         },
      //         {
      //           label: "User Type",
      //           field: "usertype",
      //           width: 100
      //         },
      //         {
      //           label: "Action",
      //           field: "action",
      //           width: 150
      //         },
      //       ],
      //     rows: this.state.userListData
      //   }
      //   })
      // }.bind(this), 200)
    }

    copyMeetingClicked(event) {
      // console.log("Copy :: " + event.currentTarget.name)
      this.setState({emailId : event.currentTarget.name})
      this.setState({password : ''})
      $("#resetPassword").modal()
    }

    validate(email,firstname,lastname,designation){
      let errors = this.state.errors;
      errors.editEmail = !validator.isEmail(email) ? 'Enter valid Email' :'';
      errors.editfirstname = validator.isEmpty(firstname) ? 'Enter First Name.': '';
      errors.editlastname = validator.isEmpty(lastname) ? 'Enter Last Name.': '';
      errors.editdesignation = validator.isEmpty(designation) ? 'Select Designation.': '';
      
      $("#editemailspan").html(errors.editEmail);
      $("#editfirstnamespan").html(errors.editfirstname);
      $("#editlastnamespan").html(errors.editlastname);
      $("#editdesignationspan").html(errors.editdesignation);

       if(errors.editEmail!=='' || errors.editfirstname!=='' || errors.editlastname!=='' || errors.editdesignation!=='' ){
          return false;  
       }else{
          return true;
      }     
   }

    saveButtonClicked(){

      if(this.state.password===''){
        this.setState({passwordErrorMessage : "Enter Password"})
        return;
      }
 
    AuthenticationService
              .updateResetPassword(this.state.emailId ,this.state.password)
              .then((response) => {
                  if(response.data!==''){
                    alert("Password Reset Successfully")
                    $("#resetPassword").modal('hide');
                    $("#resetPasswordId").val("")
                  }
              }).catch((error) => {
                if(error.response.status === 401) {
                    window.location.href = "/logout";
                }
              })
    }

    addUserClicked(){
      $("#registration").modal()
    }

    editUserClicked(event){
      //alert(event.currentTarget.name.split(",")[1])
      AuthenticationService
      .executeGetEmail(event.currentTarget.name)
      .then((response) => {
        $("#userid").val(response.data.id)
        $("#editEmail").val(response.data.email)
        $("#editfirstname").val(response.data.firstName)
        $("#editlastname").val(response.data.lastName)
        $("#editdesignation").val(response.data.designation)
        $("#edituser").modal()
      }).catch((error) => {
        if(error.response.status === 401) {
            window.location.href = "/logout";
        }
      })
    }

    updateUserData(){
      let flag = this.validate( $("#editEmail").val(), $("#editfirstname").val(),$("#editlastname").val(),$("#editdesignation").val());
      if(flag){
        RegistrationService.updateUserData($("#userid").val(), $("#editEmail").val().toLowerCase(), $("#editfirstname").val(), $("#editlastname").val(),$("#editdesignation").val())
        .then((response) => {

            // this.setState( {email: '' })
            // this.setState( {firstName: '' })
            // this.setState( {lastName: '' })
            // this.setState( {password: '' })
            // this.setState( {designation: '' })

            if(response.data!=='' && response.data.status==='duplicate'){
              alert('Email is already exist. Try with different email id')
              $("#edituser").modal('hide');
            }

            if(response.data!=='' && response.data.status===undefined){
               
                if($("#editEmail").val().trim() === AuthenticationService.getLoggedInUserName().trim()){
                  localStorage.setItem(applicationConstants.USER_FIRST_NAME_SESSION_ATTRIBUTE_NAME, $("#editfirstname").val())
                  localStorage.setItem(applicationConstants.USER_LAST_NAME_SESSION_ATTRIBUTE_NAME,  $("#editlastname").val())
                  localStorage.setItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME, response.data.userRoles[0].role.id)
                }

                alert('User details updated successfully')
                $("#edituser").modal('hide');
                window.parent.location.reload();
                
            }
      }).catch((error) => {
        if(error.response.status === 401) {
            window.location.href = "/logout";
        }
      })
      } 
    }

  handleChange(event) {
   this.setState({passwordErrorMessage : ''})
    this.setState(
        {
            [event.target.name]
                : event.target.value.trim()
        }
    )
  }

  handleChangeOnUpdate(event) {
    
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
        case 'email': 
          errors.email = !validator.isEmail(value) ? 'Enter valid Email' :'';
          $("#editemailspan").html(errors.email);
        break;
        case 'firstName': 
          errors.firstName = validator.isEmpty(value) ? 'Enter First Name.': '';
          $("#editfirstnamespan").html(errors.firstName);
        break;
        case 'lastName': 
          errors.lastName = validator.isEmpty(value) ? 'Enter Last Name.': '';
          $("#editlastnamespan").html(errors.lastName);
        break;
        case 'designation': 
          errors.designation = validator.isEmpty(value) ? 'Enter Last Name.': '';
          $("#editdesignationspan").html(errors.designation);
        break;
        default:   
        
    }
    
    this.setState({errors, [name]: value});
}


  resetValues(){
    $("#editemailspan").html("");
    $("#editfirstnamespan").html("");
    $("#editlastnamespan").html("");
    $("#editdesignationspan").html("");
    this.setState({password : ''});
    this.setState({passwordErrorMessage : ''})
  }

  removeUserClicked(event) {
    console.log("Target :: " + event.currentTarget.name)
    let id = event.currentTarget.name;

    if(window.confirm("Are you sure, you want to remove this user?")) {
        AuthenticationService
        .executeRemoveUserService(id)
        .then(() => {
          if(id.trim() === AuthenticationService.getLoggedInUserName().trim())
          {
             window.location.href="/logout"
          }else{
            window.parent.location.reload();
          }
         
        }).catch((error) => {
            console.log("Error :: " + error)
            this.setState({ showSuccessMessage: false })
            this.setState({ hasLoginFailed: true })
           
              if(error.response.status === 401) {
                  window.location.href = "/logout";
              }
          
        })
    }
}

    render(){
      if(!AuthenticationService.isUserLoggedIn()  ) {
        window.location.href = '/login'
      }

      if(localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME)==='2') {
        window.location.href = '/Meetings'
      }

      return ( 

        <div class="container-fluid">
        
        <div class="card shadow mb-4 mt-3">
          
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
          <a href="/Admin" style={{textDecoration : "underline"}}>Admin Dashboard</a>   <i className="fa fa-angle-double-right"></i>  User List
            <button type="button" className="btn btn-primary" style={{float : "right"}}
         onClick={this.addUserClicked} >Add User</button>
          </h6>
         
        </div>
      <div class="card-body">
        <form>
       <MDBDataTable responsive striped bordered hover 
       small data={this.state.data}/>
       </form>
       </div>
       

       <div className="modal fade" id="resetPassword" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Reset Password</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetValues}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="input-group">
      <input type="text" className="form-control" value={this.state.emailId}  name="emailId" id="emailId" disabled/>
      </div>
      <div className="input-group mt-3">
      <input type="password" className="form-control"
       placeholder="Enter New Password" name="password" id="resetPasswordId" 
       onChange={this.handleChange} value={this.state.password}/>
      </div>
      {this.state.passwordErrorMessage.length > 0 && <span  className="text-danger">{this.state.passwordErrorMessage}</span>}
     
      </div>
      <div className="modal-footer mt-4">
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.resetValues}>Close</button>
        <button type="button" className="btn btn-primary" onClick={this.saveButtonClicked} >Save</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="edituser" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Edit User</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetValues}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                <input type="hidden" name="userid" id="userid"/>         
                                        <p className="text-muted">Update User Data</p>
                                        
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="icon-envelope"></i></span>
                                            </div>
                                            

                                            <input type="text" className="form-control" 
                                                placeholder="Email*" 
                                                name="email" id="editEmail"
                                                onChange={this.handleChangeOnUpdate} />
                                        
                                        </div>

                                        {<span  className="text-danger" id="editemailspan">{this.state.errors.editEmail}</span>}

                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="First Name*"
                                                name="firstName" id="editfirstname"
                                                onChange={this.handleChangeOnUpdate} />
                                        </div>

                                        {<span  className="text-danger" id="editfirstnamespan">{this.state.errors.editfirstname}</span>}

                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="Last Name*"
                                                name="lastName" id="editlastname"
                                                onChange={this.handleChangeOnUpdate} />
                                        </div>

                                        { <span  className="text-danger" id="editlastnamespan">{this.state.errors.editlastname}</span>}
                                        
                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="fa fa-tasks"></i></span>
                                            </div>
                                            <select id="editdesignation" className="form-control" name="designation" onChange={this.handleChangeOnUpdate}>
                                                <option value="">--- Select Role ---</option>
                                                <option value="1">Admin</option>
                                                <option value="2">User</option>   
                                            </select>
                                        </div>
                                        { <span  className="text-danger" id="editdesignationspan">{this.state.errors.editdesignation}</span>}
                                        </div>
      <div className="modal-footer mt-4">
        <button type="button" className="btn btn-secondary"  data-dismiss="modal" onClick={this.resetValues}>Close</button>
        <button type="button" className="btn btn-primary" onClick={this.updateUserData}>save</button>
      </div>
    </div>
  </div>
</div>


</div> 
<Registration/>
</div> 
       
       
       )

    } 
}
export default UserList