import React, { Component } from 'react'

//import { jitsi_URL } from '../utils/applicationConstants'
import 'bootstrap/dist/css/bootstrap.css';
import AuthenticationService from '../authentication/AuthenticationService';
//import validator from 'validator'


class ResetPassword extends Component {
    constructor(props) {
       
        super(props)

        this.state = {
            email: '',
            password:'',
            confirmPassword:'',
            buttonValue: "Reset Password",
            emailErrorMessage : '',
            passwordErrorMessage : '',
            successMessage : '',
            errorMessage : '',
            emptyPasswordErrorMessage : ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.resetPasswordClicked = this.resetPasswordClicked.bind(this)

    }

    handleChange(event) {
        this.setState( { emailErrorMessage: ''})
        this.setState( { passwordErrorMessage: ''})
        this.setState({emptyPasswordErrorMessage : ''})

        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

    resetPasswordClicked() {

        if(!this.state.password){
            this.setState({emptyPasswordErrorMessage : 'Enter Password'}) 
            return;
        }
        
        if(this.state.password===this.state.confirmPassword) 
        {    
            let url = window.location.href;
            let length=url.trim().split("/").length;
            let passwordKey =  url.trim().split("/")[length-1];
            
            AuthenticationService.executeResetPassword(passwordKey,this.state.password).then((response) => {
               
                if(response.data!==''){
                   this.setState({successMessage : 'Password reset successfully'})
                   window.location.href = '/login' 
                }else{
                   this.setState({errorMessage : 'Something went wrong . Please try again later'})
                   window.location.href = '/login' 
                }
            })
            
        }else{
             this.setState({passwordErrorMessage : 'New Password and Confirm Password does not match.'})  
        }

    }
    
    componentDidMount() {
        let url = window.location.href;
        let length=url.trim().split("/").length;
        let passwordKey =  url.trim().split("/")[length-1];

        AuthenticationService.executeFindUserByPasswordKey(passwordKey).then((response) => {
          
            if(response.data!==''){
                this.setState( { email: response.data});
            }else{
                window.location.href = '/login' 
            }
        })
      }

    render() {
      
        return(
            <div>
            <main className="main d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="card-group" style={{marginTop: "10%"}}>
                                <div className="card">
                                    
                                    <div className="card-body">
                                        {/* {this.state.hasJoinRoomFailed &&
                                            <div className="alert alert-danger" role="alert">
                                                Invalid meeting name or password
                                            </div>
                                        } */}
                                        <p className="text-muted"><b>Reset Password</b></p>
                                        
                                        {this.state.passwordErrorMessage.length > 0 && <span  className="text-danger ml-6"><b>{this.state.passwordErrorMessage}</b></span>}
                                        {this.state.successMessage.length > 0 && <span  className="text-success ml-6"><b>{this.state.successMessage}</b></span>}
                                        {this.state.errorMessage.length > 0 && <span  className="text-danger ml-6"><b>{this.state.errorMessage}</b></span>}
                                       
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="Email*" autoComplete="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange} readOnly = {true} />
                                        </div>

                                        {this.state.emailErrorMessage.length > 0 && <span  className="text-danger ml-6">{this.state.emailErrorMessage}</span>}
                                     
                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="password" className="form-control" 
                                                placeholder="New Password*" autoComplete="password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.handleChange} />
                                        </div>

                                        {this.state.emptyPasswordErrorMessage.length > 0 && <span  className="text-danger ml-6">{this.state.emptyPasswordErrorMessage}</span>}

                                        <div className="input-group  mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="password"  className="form-control" 
                                                placeholder="Confirm Password*" autoComplete="confirmPassword"
                                                name="confirmPassword"
                                                value={this.state.confirmPassword}
                                                onChange={this.handleChange} />
                                        </div>

                                        <div className="row">
                                            <div className="col-6 mt-3">
                                                <button type="submit" className="btn btn-primary px-4" 
                                                    onClick={this.resetPasswordClicked}>
                                                    {this.state.buttonValue}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
</div>
         
        )
    }
}

export default ResetPassword