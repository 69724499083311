import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import AuthenticationService from '../authentication/AuthenticationService';
import './header.css';
import * as applicationConstants from '../utils/applicationConstants';

    const Header = (props) => {
    let loc_array = window.location.href.split("/");
    let tabValue = loc_array[loc_array.length - 1];
    let isLoggedIn = AuthenticationService.isUserLoggedIn();
      
    function loggedout(){
        if(AuthenticationService.getLoggedInUserName()){
            AuthenticationService.executeLogOut(AuthenticationService.getLoggedInUserName()).then((response)=>{
                    window.location.href='/logout'
            })
        }else{
            window.location.href='/logout'
        }
       
      }

    console.log("Tab Value :: " + tabValue)
    console.log("isLoggedIn :: " + isLoggedIn)
    
    return(
        <div>
            <header>
                
                <nav className="navbar navbar-expand-lg navbar-light bg-primary" style={{padding : "0px 0px"}}>
                    <ul className="nav navbar-nav d-md-down">
                        <li className="nav-item">
                        <span className="nav-link">
                            <h4  className="text-light1 ml-2">Video Conference System</h4>
                            {/* <img src={process.env.PUBLIC_URL + '/watermark.png'} alt="Logo" className="img-avatar" /> */}
                        </span>
                        </li>
                       
                        {isLoggedIn &&
                        tabValue !== 'login'  && 
                        
                        <li className="nav-item mt-1 ml-2">
                            <a className="nav-link text-light1" style={{cursor: "pointer"}} href="/Meetings">Meetings</a>  
                        </li>
                        }

                        {isLoggedIn &&
                        tabValue !== 'login' && localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME) === '1' && 
                        <li className="nav-item mt-1 ml-2">
                            <a className="nav-link text-light1" style={{cursor: "pointer"}} href="/Admin">Admin Dashboard</a>  
                        </li>
                        
                        }

                        {/* {isLoggedIn &&
                        tabValue !== 'login' && localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME) === '1' && 
                        <li className="nav-item mt-1 ml-2">
                            <a className="nav-link text-light1" style={{cursor: "pointer"}} href="/userlist">User List</a>  
                        </li>
                        } */}

                    </ul>

                    <ul className="navbar-nav ml-auto">
                            {
                                isLoggedIn &&
                                <li className="nav-item dropdown" dropdown="" placement="bottom right">
                                    <a aria-haspopup="true" className="nav-link" data-toggle="dropdown" dropdowntoggle="" href="#" role="button" aria-expanded="false">
                                          <h5 className="float-left mt-2 text-light1">Welcome, {AuthenticationService.getFirstNameLastName()}</h5>
                                        <img alt="admin@bootstrapmaster.com" className="img-avatar" src={process.env.PUBLIC_URL + "/avtar.png"} />
                                    </a>
                                    <div aria-labelledby="simple-dropdown" className="dropdown-menu" 
                                    style={{left: "auto", right: "0px", position: "absolute"}}>
                                       <span className="dropdown-item" style={{cursor: "pointer"}} onClick={loggedout}>
                                            <i className="icon-lock"></i> Logout </span>
                                    </div>
                                </li>
                            }
                            {/* <li className="nav-item">
                            {
                                !isLoggedIn &&
                                (tabValue !== 'registration' && tabValue !== 'Registration') &&
                                    <a className="nav-link" href="/registration">Register</a>  
                            }
                            </li> */}
                            
                             <li className="nav-item">
                            {
                                !isLoggedIn &&
                                (tabValue !== '' && tabValue === 'forgotPassword') &&
                                    <a className="nav-link text-light1" href="/login">Login</a>  
                            }
                            </li> 

                            {/* <li className="nav-item">
                            {
                                !isLoggedIn &&
                                (tabValue !== 'joinRoom' && tabValue !== 'JoinRoom') &&
                                <a className="nav-link" href="/joinRoom">Join Room</a>
                            }
                            </li> */}
                    </ul>
                </nav>
            </header>
        </div>
        )
}
export default Header