import React, { Component } from 'react'
import RoomService from './RoomService';
import { jitsi_URL } from '../utils/applicationConstants'
import AuthenticationService from '../authentication/AuthenticationService';
import $ from 'jquery';
import MeetingPopup from '../room/meetingpopup';
import  './roomList.css';


class RoomList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            roomDetails: [],
            url:'',
            isJoinedClicked : false,
            jwtToken: '',
            meetingId: ''

        }

        this.createRoomClicked = this.createRoomClicked.bind(this)
        this.removeRoomClicked = this.removeRoomClicked.bind(this)
        this.copyMeetingClicked = this.copyMeetingClicked.bind(this)
        
    }
    componentWillMount() {
       // alert((window.location.href.split("?")[1]).split("=")[1])
    
        RoomService.executeGetRoomDetailsService()
        .then(
            (response) => {
                console.log("Response:: " + response.data)
                this.setState( { 
                    roomDetails: response.data 
                } )
            }
        ).catch(
            (error) => {
                console.log("Error :: " + error);
                
                    if(error.response.status === 401) {
                        window.location.href = "/logout";
                    }
                
            }
        )
    }

    createRoomClicked(event) {
        let roomId = event.target.name;
        AuthenticationService
            .getJWTTokenService(roomId)
            .then((response) => {

                for (var key in response.data) {
                    if (response.data.hasOwnProperty(key)) {
                        this.setState({jwtToken: response.data[key]})
                        this.setState({meetingId: roomId})
                        this.setState({isJoinedClicked:true})
                        $('#MeetingPopup1').modal('show');
                        // this.setState({url:`${jitsi_URL}/${roomId}?${key}${response.data[key]}`})
                        //window.open(`${jitsi_URL}/${roomId}?${key}${response.data[key]}`,'_self')
                    }
                }
             
                
            }).catch((error) => {
                console.log("Response Type :: " + error.response.status)
                console.log("Error :: " + error)
        
                    if(error.response.status === "401") {
                        window.location.href = "/logout";
                    }
                  
                // this.setState({ showSuccessMessage: false })
                // this.setState({ hasLoginFailed: true })
            })
    }

    removeRoomClicked(event) {
        console.log("Target :: " + event.currentTarget.name)
        let id = event.currentTarget.name;
        if(window.confirm("Are you sure, you want to delete this meeting?")) {
            RoomService
            .executeRemoveRoomService(id)
            .then(() => {
                window.location.href = "/welcome"
            }).catch((error) => {
                console.log("Error :: " + error)
               
                    if(error.response.status === "401") {
                        window.location.href = "/logout";
                    }
                
                this.setState({ showSuccessMessage: false })
                this.setState({ hasLoginFailed: true })
            })
        }
    }

    copyMeetingClicked(event) {
        console.log("Copy :: " + event.currentTarget.name)
        let roomDetail = this.state.roomDetails[event.currentTarget.name]
        let urlText = jitsi_URL
        urlText += "?meetingId="+ roomDetail.roomId
        let messageText = 'Meeting Id: ' + roomDetail.roomId +'<br>Meeting URL: ' + urlText + '<br>Meeting Password: ' + roomDetail.roomPass ;
        console.log(messageText)
        $("#copyModelBody").html(messageText)
        $("#copyModel").modal()
    }

    render() {
        return(
            
                            <div className="card" style={{width: "18rem,",}}>
                               
                                <ul className="list-group list-group-flush">
                                {
                                    this.state.roomDetails.map((roomDetail, index) => (
                                        
                                        <li className="list-group-item" key={index}>
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <h5><span><i className="icon-home"></i> {roomDetail.roomId} </span> </h5>
                                                    <small>{jitsi_URL}?meetingId={roomDetail.roomId}</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <button type="button" name={roomDetail.roomId} className="btn btn-primary px-3 icon" 
                                                        onClick={this.createRoomClicked}
                                                    >
                                                        Start Meeting
                                                    </button>
                                                   
                                                </div>
                                                <div className="col-md-2">
                                                    <button type="button" name={index} className="btn icon" onClick={this.copyMeetingClicked}>
                                                        <span><i className="icon-copy"></i></span>
                                                    </button>
                                                    <button type="button" name={roomDetail.id} className="btn icon" onClick={this.removeRoomClicked}>
                                                    <span><i className="icon-trash"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                       
                                       
                                    ))  
                                }
                                
                                </ul>
                               
                                {/* <MeetingPopup id="1" 
                                         meetingUrl={this.state.url} isJoined={this.state.isJoinedClicked}/> */}

            {/* Copy Room Details Module */}
<div className="modal fade" id="copyModel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalCenterTitle">Copy Message</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" id="copyModelBody">
        
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" 
            onClick={() => {
                let meetingText = $("#copyModelBody").html();
                meetingText = meetingText.replace(/<br>/g, "\n");
                console.log(meetingText);
                // navigator.clipboard.writeText(meetingText);
                
                document.addEventListener('copy', (e: ClipboardEvent) => {
                e.clipboardData.setData('text/plain', (meetingText));
                e.preventDefault();
                document.removeEventListener('copy', null);
                });
                document.execCommand('copy');
                
                $("#copyModel").modal('hide');
                window.alert("Meeting details copied!")
            } } >
            Copy
        </button>
      </div>
    </div>
  </div>
</div>
{/* Copy Room Details Module */}

<MeetingPopup id="1" 
                                         meetingId={this.state.meetingId} 
                                         isJoined={this.state.isJoinedClicked}
                                         jwtToken={this.state.jwtToken}
                                         />


                            </div>
    //         <div>
            // <main className="main d-flex align-items-center">
            //     <div className="container">
            //         <div className="row">
            //             <div className="col-md-8 mx-auto">
            //                 <div className="card-group" style={{marginTop: "10%"}}>
            //                     <div className="card">
            //                         <div className="card-header bg-primary text-light">
            //                             <h3>Welcome!</h3>
            //                         </div>
            //                         <div className="card-body">
    //                                     <p className="text-muted">Join or Create Room on Video Conference System</p>
    //                                     <div className="input-group mb-3">
    //                                         <input type="text" className="form-control" 
    //                                             placeholder="Enter your room name" autoComplete="roomId"
    //                                             name="roomId"
    //                                             value={this.state.roomId} 
    //                                             onChange={this.handleChange}   
    //                                          />
    //                                     </div>

    //                                     <div className="row">
    //                                         <div className="col-6">
    //                                             <button type="submit" className="btn btn-primary px-4" 
    //                                                 onClick={this.joinRoomClicked}
    //                                             >
    //                                                 Join Room
    //                                             </button>
    //                                         </div>
    //                                         <div className="col-6">
    //                                             <button type="submit" className="btn btn-primary px-4" 
    //                                                 onClick={this.createRoomClicked}
    //                                             >
    //                                                 Create Room
    //                                             </button>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </main>
    //         </div>
            
    //         // <div>
    //         //     <Row align="middle">
    //         //         <Col span={12}  offset={6}>
    //         //             <Card title="Join or Create Room on Video Conference System" bordered={true} 
    //         //                 style={{marginTop: '10%'}}
    //         //                 headStyle={{backgroundColor: '#20a8c5', color: 'white'}}>
    //         //                 <Input
    //         //                     placeholder="Enter your room name"
    //         //                     prefix={<UserOutlined className="site-form-item-icon" />}
    //         //                     suffix={
    //         //                         <Tooltip title="If you are Host than enter room name and create room, otherwise enter room name and join the room.">
    //         //                             <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
    //         //                         </Tooltip>
    //         //                     }
    //         //                     name="roomId"
    //         //                     value={this.state.roomId} 
    //         //                     onChange={this.handleChange}
    //         //                 />
    //         //                 <br /><br />
    //         //                 <br /><br />
    //         //                 <Space>
    //         //                     <Button type="primary" onClick={this.joinRoomClicked}>Join Room</Button>
    //         //                     <Button type="primary" onClick={this.createRoomClicked}>Create Room</Button>
    //         //                 </Space>
    //         //                 </Card>
    //         //         </Col>
    //         //     </Row>
    //         // </div>
        )
    }
}

export default RoomList