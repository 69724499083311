import React, { Component } from 'react'

//import { jitsi_URL } from '../utils/applicationConstants'
import 'bootstrap/dist/css/bootstrap.css';
import AuthenticationService from '../authentication/AuthenticationService';
import validator from 'validator';
import $ from 'jquery';

class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            buttonValue: "Forgot Password",
            emailErrorMessage : '',
            successMessage : '',
            isDisable : false        }

        this.handleChange = this.handleChange.bind(this)
        this.forgotPasswordClicked = this.forgotPasswordClicked.bind(this)
    }

    handleChange(event) {
        this.setState( { emailErrorMessage: ''})
        this.setState({successMessage : ''})
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

    forgotPasswordClicked() {
        
        if(validator.isEmail(this.state.email)) {
            
            AuthenticationService.executeGetEmail(this.state.email).then((response) => {
                if(response.data!==''){
                    this.setState( { isDisable: true })
                    this.setState( { buttonValue: 'Please Wait...'})
                    AuthenticationService.executeForgotPassword(this.state.email)
                    .then((response) => {
                       if(response.data!==''){
                        this.setState( { isDisable: false })
                        this.setState( { buttonValue: 'Forgot Password' }) 
                        this.setState( { successMessage: 'Email is successfully sent to registered email id'}) 
                        $("#successMessage").fadeOut(4000)
                       }else{
                        this.setState( { emailErrorMessage: 'Something went wrong...Please try again later'}) 
                        $("#emailErrorMessage").fadeOut(4000);
                        this.setState( { isDisable: false })  
                        this.setState( { buttonValue: 'Forgot Password' })
                       }

                    })
                }else{
                    this.setState( { isDisable: false })  
                    this.setState( { buttonValue: 'Forgot Password' }) 
                    this.setState( { emailErrorMessage: 'Email is not registered in our system'}) 
                }
            })
            
        }else{
            this.setState( { emailErrorMessage: 'Enter valid email'}) 
        }
    }

    render() {
        if(AuthenticationService.isUserLoggedIn()) {
            window.location.href = '/welcome'
        }
        return(
            <div>
            <main className="main d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="card-group" style={{marginTop: "10%"}}>
                                <div className="card">
                                    {/* <div className="card-header bg-primary text-light">
                                        <h3>Video Conference System</h3>
                                    </div> */}
                                    <div className="card-body">
                                        {/* {this.state.hasJoinRoomFailed &&
                                            <div className="alert alert-danger" role="alert">
                                                Invalid meeting name or password
                                            </div>
                                        } */}
                                        <p className="text-muted"><b>Forgot Password</b></p>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="Email*" autoComplete="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange} />
                                        </div>

                                        {this.state.successMessage.length > 0 && <span  className="text-success ml-6" id="successMessage">{this.state.successMessage}</span>}
                                        {this.state.emailErrorMessage.length > 0 && <span  className="text-danger ml-6" id="emailErrorMessage">{this.state.emailErrorMessage}</span>}
                                     
                                        <div className="row">
                                            <div className="col-6 mt-3">
                                                <button type="submit" className="btn btn-primary px-4" 
                                                    onClick={this.forgotPasswordClicked}  disabled={this.state.isDisable}>
                                                    {this.state.buttonValue}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
</div>
         
        )
    }
}

export default ForgotPassword