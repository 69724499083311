import React from 'react';
import './App.css';
import { Router } from "@reach/router"
import Logout from './header/logout';
import Login from './login/loginPage'
import Home from './home/homePage';

import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import AuthenticatedRoute from './authentication/authenticatedRoute';
import ForgotPassword from './login/forgotPassword';
import ResetPassword from './login/resetPassword';
import CloseIFrame from './utils/closeIFrame';
import * as applicationConstants from './utils/applicationConstants';
//import Registration from './registration/registrationPage';
import Admin from './room/Admin';
import UserList from './home/userlist';
import Meetings from './welcome/welcomePage';
let loc_array = window.location.href.split("/");
let tabValue = loc_array[loc_array.length - 1];
class App extends React.Component {


  constructor(props) {
    super(props);

    this.state = { logginStatus: true };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.logoutTimeout = setTimeout(this.logout, 14400 * 1000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  logout() {
 
    // Send a logout request to the API
    console.log("Sending a logout request to the API...");
  
    if(tabValue!=='' && tabValue!=='forgotPassword' &&  tabValue!=='resetPassword'){
      window.location.href = "/logout";
    }
    
    // this.setState({ logginStatus: false });

    // this.destroy(); // Cleanup
  }

  destroy() {
    this.clearTimeout();
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  render() {
  return (

    <div>
    <Home>
      <Router>
                {/* <Route exact path="/" component={Login}>
                </Route>

                <Route exact path="/login" component={Login}>
                </Route>
      
                <Route exact path="/registration" component={Registration}>
                </Route> */}
                         
                {/* <AuthenticatedRoute path="/welcome" component={Welcome}>
                </AuthenticatedRoute> */}

                {/* <Route exact path="/logout" component={Logout}>
                </Route> */}

                <Login default />
                <Login path="/login" />
               
                {/* {AuthenticationService.isUserLoggedIn()  && */}
                <Meetings path="/Meetings" /> 
                <UserList path="userlist"/> 
                {/* } */}
                {/* <Registration path="registration" />    */}
                <ForgotPassword path="forgotPassword"/>
                <ResetPassword path="resetPassword/:passwordKey"/>
                {/* <AdminDashboard path="admindashboard"/>  */}
                
                {/* <Login path="joinRoom/:meetingId" /> 
                <Login path="joinRoom" /> */}
                {localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME)==='1' &&
                  <AuthenticatedRoute as={Admin} default />
                }

                {localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME)==='2' &&
                  <AuthenticatedRoute as={Meetings} default />
                }
               
                <Logout path="/logout" />
                <CloseIFrame path="/closeIFrame" />
      </Router>
    </Home>
    </div>
  );

}

}

export default App;