import { Component } from 'react';
import * as applicationConstant from '../utils/applicationConstants'
import axios from 'axios';
import AuthenticationService from '../authentication/AuthenticationService';

class RegistrationService extends Component {

    saveUserData(userProfile) {
        let email = userProfile.email.toLowerCase()
        let firstName = userProfile.firstName
        let lastName = userProfile.lastName
        let designation = userProfile.designation
        let password = userProfile.password
        let token = AuthenticationService.getSecurityToken();
        return axios.post(`${applicationConstant.API_URL}/user_role/s/usr/saveUser`, {
            email,
            firstName,
            lastName,
            designation,
            password   
        },
        {
            headers: {
            'Authorization': token
            }
        }
        )
    }

    updateUserData(id,email,firstName,lastName,designation){
        let token = AuthenticationService.getSecurityToken();
        return axios.post(`${applicationConstant.API_URL}/userProfile/s/usr/updateUser`, {
            id,
            email,
            firstName,
            lastName,
            designation
        },
        {
            headers: {
            'Authorization': token
            }
        }
        )
    }
}

export default new RegistrationService()