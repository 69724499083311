import React, { Component } from "react";
import Chart from "react-apexcharts";
import AuthenticationService from '../authentication/AuthenticationService';
import RoomService from './RoomService';
import $ from 'jquery';
import  './admindashboard.css';
import axios from 'axios';
import RoomHistory from './roomhistory'

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

class Admin extends Component {

  constructor(props) {
    super(props);

    
    this.state = {
          
        series: [{
          name: "Participants",
          data: [],
        }],

        options: {
          title: {
            text: "Meetings",
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '18px',
              fontWeight:  'bold',
              color:  '#263238'
            },
        },
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
          },
          // yaxis: {
          //   tickAmount: 6,
          //   min: 1,
          //   floating: false,
          //   decimalsInFloat: undefined,
          //   max: 6,
          // }
          yaxis: {   
            min : 0,
            max : 6,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return value },
            },   
        }
        
          },
          loggedInUserCount : '' ,
          roomsCount : 0,
          participants : 0,
          rooms : [],
          dataPoints : [],
          participantCountByRoomId : 0,
          twoPoints : [] , 
          roomId : '',
          currentDateTime: Date().toLocaleString()
      
      };
     // this.componentWillMount = this.componentWillMount.bind(this)
      this.refreshData = this.refreshData.bind(this)
    }

    async getData() {
      for (var i = 0; i < this.state.rooms.length; i++) {
          let wttURL = `https://conference.rpathos.com/room-size?room=` + this.state.rooms[i]
          await axios.get(wttURL).then(response1 => {
            this.state.dataPoints.push(response1.data.participants)
            console.log(response1.data.participants+"    "+this.state.rooms[i])

            if(response1.data.participants>6){
                    this.setState({
                         options: {
                            yaxis: {
                               max : response1.data.participants
                              }
                      }
                  })
              }
      })
    }
    console.log('6')

    await this.setState({
      series: [{
          name: "Participants",
          data: this.state.dataPoints.sort(function(a, b){return a>b})
        }]
    }) 
   
  }


    // async componentWillMount() {

    //    // alert(this.state.options.xaxis.categories)
    //    // alert(this.state.series[0].data)
    
    //     AuthenticationService.executeGetLoggedInUserCount()
    //     .then(
    //         (response) => {
    //            this.setState({loggedInUserCount : response.data});
    //         }
    //     ).catch(
    //         (error) => {
    //             console.log("Error :: " + error);
    //         }
    //     )

    //      RoomService.executeGetActiveRoomDetailsService()
    //     .then(
    //         (response) => {
    //           // alert(JSON.stringify(response.data[0].rooms))
    //            this.setState({roomsCount : response.data[0].roomscount})
    //            this.setState({participants : response.data[0].participants})
               
    //             if(!isEmpty(response.data[0].rooms)){                  
    //                 this.setState({rooms :  response.data[0].rooms})
    //                 this.setState({
    //                     options: {
    //                         xaxis: {
    //                             categories: response.data[0].rooms ,
    //                         }
    //                     }
    //                   })
                   
    //                 response.data[0].rooms.map(function(room) {
    //                      RoomService.executeParticipantSizeByRoomId(room).then(
    //                         (response1) => {

    //                            this.state.twoPoints.push(response1.data.participants);
    //                            this.setState({dataPoints : this.state.twoPoints})

    //                            if(response1.data.participants>5){
    //                             this.setState({
    //                               options: {
    //                                   yaxis: {
    //                                       max : response1.data.participants
    //                                   }
    //                               }
    //                             })
    //                            }
                               
                            
    //                         //   this.setState({
    //                         //     options: {
    //                         //         yaxis : {
    //                         //             max : parseInt(response1.data.participants)+1,
    //                         //         }
    //                         //     }
    //                         //   })


    //                         //     //alert(room)
    //                         //     //alert(response1.data.participants)
    //                         //    // alert(JSON.stringify(this.state.rooms))
    //                         //     this.state.twoPoints.push({name:  room , num: response1.data.participants , });
    //                         //     //alert(JSON.stringify(this.state.rooms))
    //                         //     this.setState({rooms : this.state.twoPoints}); 
                                
    //                         },
                           
    //                     )
                       
    //                  }.bind(this));

    //             }
               
    //     }) 
    //     setTimeout(function afterTwoSeconds() {
    //         console.log('2')
    //             this.setState({
    //                 series: [{
    //                     name: "Participants",
    //                     data: this.state.dataPoints
    //                   }]
    //               }) 
    //       }.bind(this), 15000)
    // }


    async refreshData() {

    //  this.setState({twoPoints : []})
    //  this.setState({dataPoints : []})
      this.setState({currentDateTime : Date().toLocaleString()})
    
       AuthenticationService.executeGetLoggedInUserCount()
       .then(
           (response) => {
              this.setState({loggedInUserCount : response.data});
           }
       ).catch(
           (error) => {
               console.log("Error :: " + error);
               if(error.response.status === 401) {
                window.location.href = "/logout";
            }
           }
       )

        RoomService.executeGetActiveRoomDetailsService()
       .then(
           (response) => {
             // alert(JSON.stringify(response.data[0].rooms))
              this.setState({roomsCount : response.data[0].roomscount})
              this.setState({participants : response.data[0].participants})
              
               if(!isEmpty(response.data[0].rooms)){  
                   $("#graphRow").show();                
                   this.setState({rooms :  response.data[0].rooms})
                   this.setState({
                       options: {
                           xaxis: {
                               categories: response.data[0].rooms ,
                           }
                       }
                     })
                    //alert(this.state.rooms)
                
                   this.setState({dataPoints : []})
                   this.getData()
                  //  let promises = [];
                  //  let users = [];
                   for(var i=0;i< response.data[0].rooms.length;i++) {
                      // axios.get(`https://conference.rpathos.com/room-size?room=`+response.data[0].rooms[i]).then(response1 => {
                      //   // do something with response
                      //   users.push(response1.data.participants);
                      // })


                    //alert(response.data[0].rooms[i])
                      //   RoomService.executeParticipantSizeByRoomId(response.data[0].rooms[i]).then(
                      //      (response1) => {
                             



                            
                      //       // alert("before :: " +"rooms: " +room + "  twoPoints: " +this.state.twoPoints)
                      //        // this.state.twoPoints.push(response1.data.participants);
                      //         this.state.dataPoints.push(response1.data.participants)
                      //         //alert("After :: " +"rooms: " +response.data[0].rooms[i] + "  dataPoints: " +this.state.dataPoints)
                          
                          
                      //        if(response1.data.participants>6){
                      //              this.setState({
                      //                options: {
                      //                  yaxis: {
                      //                    max : response1.data.participants
                      //                   }
                      //                 }
                      //               })
                      //          }
                          
                          
                      //       },
                      //  )
                      
                    }
                   // Promise.all(promises).then(() => console.log(users));
               }else{
                  $("#graphRow").hide();
               }
              
       }).catch((error) => {
        console.log("Error :: " + error)
    
            if(error.response!==undefined && error.response.status === 401) {
                window.location.href = "/logout";
            }
    })
      //  setTimeout(async function afterTwoSeconds() {
      //      console.log('6')
      //          this.setState({
      //              series: [{
      //                  name: "Participants",
      //                  data: this.state.dataPoints
      //                }]
      //            }) 
      //    }.bind(this), 6000)
   }

    componentDidMount(){
      if(isEmpty(this.state.rooms)){
          $("#noDataSubDiv").html('<H2 style="background-color: #007bff; border-radius: 10px;color: white; padding: 15%; text-align : center">No Data Available</H2> <br>');
      }
      
      this.refreshData();
      setInterval(this.refreshData , 15000)
  }

  render() {
   
    return (

    <div class="container-fluid">
      <div class="card1 shadow1 mb-4 mt-3">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary1">Admin Dashboard</h6>
          </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card1 border-left-primary shadow1 h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-primary1 text-uppercase mb-1">Active Users</div>
                      <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.loggedInUserCount}</div>
                    </div>
                    <div class="col-auto">
                      <i class="icon-user fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card1 border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-primary1 text-uppercase mb-1">Meetings</div>
                      <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.roomsCount}</div>
                    </div>
                    <div class="col-auto">
                      <i class="icon-home fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card1 border-left-primary shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-primary1 text-uppercase mb-1">Participants</div>
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.participants}</div>
                    </div>
                    <div class="col-auto">
                      <i class="icon-user fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card1 border-left-primary shadow1 h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center icon" style={{cursor : "pointer"}} onClick={()=>window.location.href="/userlist"}>
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-primary1 text-uppercase mb-1">User List</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-list fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

              <div class="card shadow mb-4">  
                <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample">
                  <h6 class="m-0 font-weight-bold text-primary">Room History</h6>
                </a>
                <div class="collapse show" id="collapseCardExample">
                  <div class="card-body">
                    <RoomHistory/>
                  </div>
                </div>
              </div>
        
          <div class="row" id="graphRow" style={{display : "none"}}>
            <div class="col-lg-6 mb-4">
              <div class="card1 shadow1 mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary1">Meetings Overview</h6>
                </div>
               
                {this.state.rooms.length!==0 && <div class="card-body" id="graphdiv">
                <Chart options={this.state.options} series={this.state.series} type="bar"/>
                </div>}
                {/* {this.state.rooms.length===0 && <div class="card-body" id="noDataSubDiv"> 
                <Chart options={this.state.options} series={this.state.series} type="bar"/>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      
//       <div class="container-fluid">
//       <div class="card shadow mb-4 mt-3">
//       <div class="card-header py-3">
//         <h6 class="m-0 font-weight-bold text-primary">Dashboard</h6>
//       </div>
//     <div class="card-body">

// <div class="row">
// <p class="col-xl-6 col-sm-6">
//   Last Updated : { this.state.currentDateTime }
// </p>
// </div>
// <div>
// 			<div class="row mb-3">
//                 <div class="col-xl-4 col-sm-6 py-2">
//                     <div class="card bg-success text-white h-100">
//                         <div class="card-body bg-primary">
//                             <div class="rotate">
//                                 <i class="fa fa-user fa-4x"></i>
//                             </div>
//                             <h6 class="text-uppercase">Active Users</h6>
//                             <h1 class="display-4">{this.state.loggedInUserCount}</h1>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="col-xl-4 col-sm-6 py-2">
//                     <div class="card text-white h-100">
//                         <div class="card-body bg-primary">
//                             <div class="rotate">
//                                 <i class="fa fa-list fa-4x"></i>
//                             </div>
//                             <h6 class="text-uppercase">Meetings</h6>
//                             <h1 class="display-4">{this.state.roomsCount}</h1>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="col-xl-4 col-sm-6 py-2">
//                     <div class="card text-white h-100">
//                         <div class="card-body bg-primary">
//                             <div class="rotate">
//                                 <i class="fa fa-twitter fa-4x"></i>
//                             </div>
//                             <h6 class="text-uppercase">Participants</h6>
//                             <h1 class="display-4">{this.state.participants}</h1>
//                         </div>
//                     </div>
//                 </div>
//             </div>
// 			</div>

//       {this.state.rooms.length===0 && 
//             <div class="row" id="noDataDiv">
//                 <div class="col-xl-6 col-sm-6 offset-3" id="noDataSubDiv"> 
// 	        </div>
//             </div>
//       }
			           
//        {this.state.rooms.length!==0 && 
//          <div className="row" id="canvasDiv">
//           <div className="col-xl-6 col-sm-6  offset-3">
//             <Chart
//               options={this.state.options}
//               series={this.state.series}
//               type="bar"
//               width="75%"
//             />
//           </div>
//        </div> }
    
//         </div>
//         </div>
//         </div>
      
    );
  }
}

export default Admin;