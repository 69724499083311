import React from 'react'
//import Iframe from 'react-iframe'

//import { jitsi_URL } from '../utils/applicationConstants'
import 'bootstrap/dist/css/bootstrap.css';
import RoomService from './RoomService';
import MeetingPopup from '../room/meetingpopup';
import $ from 'jquery'


class JoinRoomAuthenticationPage  extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            meetingId: this.props.meetingId,
            password: '',
            passwordErrorMessage:'',
           // hasJoinRoomFailed: false,
            isJoinRoomDisable: false,
            buttonValue: "Join Meeting",
            roomErrorMessage : '',
            nameErrorMessage : '',
            isJoinedClicked : false ,
            participantname : ''
           // isJoinedDisable : this.props.meetingId ? false : true
        }
        this.handleChange = this.handleChange.bind(this)
        this.joinRoomClicked = this.joinRoomClicked.bind(this)
        this.saveButtonClicked = this.saveButtonClicked.bind(this)
        this.resetValues = this.resetValues.bind(this)
        this.escFunction = this.escFunction.bind(this)
    }

    resetValues(){
        this.setState( { buttonValue: 'Join Meeting'})
        this.setState( { isJoinRoomDisable: false })
        $("#participantID").val("");
        this.setState({nameErrorMessage : ''})
    }

    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    
    componentWillUnmount(){
          document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event){
        if(event.keyCode === 27) {
          this.resetValues();
        }
    }

    handleChange(event) {
        this.setState( { roomErrorMessage: ''})
        this.setState( { nameErrorMessage: ''})
        //this.setState({isJoinedDisable : false})
        this.setState({passwordErrorMessage : ''})
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

    saveButtonClicked(){

        if($("#participantID").val()===''){
            this.setState({nameErrorMessage : 'Enter Your Name.'});
        }else{ 
            RoomService
            .updateParticipantCount($("#participantID").val().toLowerCase(),$("#roomId").val())
            .then(() => {
            })   
            $("#participantnamepopup").modal('hide');
            this.setState({isJoinedClicked:true})
            this.setState({participantname : $("#participantID").val()})
            $('#MeetingPopup0').modal('show'); 
        }
    }

    joinRoomClicked() {

        if(!this.state.password){
            this.setState( { passwordErrorMessage : 'Enter Password'}) ;
        }
        
        if(this.state.meetingId && this.state.password) {
            //this.setState( { isJoinRoomDisable: true })
            var pattern = new RegExp("^(?:[a-zA-Z0-9_]*)?$");
            if(pattern.test(this.state.meetingId)){  
                this.setState( { buttonValue: 'Please Wait...'})
                this.setState( { isJoinRoomDisable: true })
                RoomService
                .executeJoinRoomAuthenticationService(this.state.meetingId,this.state.password)
                .then((response) => {
                 if(response.data!==''){

                    $("#participantnamepopup").modal();
                    $("#roomId").val(this.state.meetingId);


                    // this.setState({isJoinedClicked:true})
                    // $('#MeetingPopup0').modal('show');
                 //this.setState({isIframeEnabled:true})
                // this.setState({isJoinedClicked:false})
                  //window.open(`${jitsi_URL}/${this.state.roomId.trim()}`);
                 }else{
                  this.setState( { roomErrorMessage: 'Meeting name does not exist'}) 
                  this.setState( { buttonValue: 'Join Meeting'})
                  this.setState( { isJoinRoomDisable: false })
                 }
                }).catch((error) => {
                    if(error.response.status===401){
                        this.setState( { passwordErrorMessage : 'Password is incorrect'}) 
                        this.setState( { buttonValue: 'Join Meeting'})
                        this.setState( { isJoinRoomDisable: false })
                    }
                 })
            }  else{
                this.setState( { roomErrorMessage: 'Enter valid meeting name'})  
                this.setState( { buttonValue: 'Join Meeting'})
                this.setState( { isJoinRoomDisable: false })
            }    
         
        }else{
            if(!this.state.meetingId)this.setState( { roomErrorMessage: 'Enter meeting name'}) 
            this.setState( { buttonValue: 'Join Meeting'})
            this.setState( { isJoinRoomDisable: false })
        }
    }

    // joinRoomClicked() {
    //     if(this.state.roomId && this.state.password) {
    //         this.setState( { isJoinRoomDisable: true })
    //         this.setState( { buttonValue: 'Please Wait...'})
            
    //         RoomService
    //         .executeJoinRoomAuthenticationService(this.state.roomId, this.state.password)
    //         .then((response) => {
    //             console.log("Response:: " + response.data)
    //             window.open(`${jitsi_URL}/${this.state.roomId}?jwt=${response.data}`)
    //             //window.location.href = `${jitsi_URL}/${this.state.roomId}?jwt=${response.data}`
    //         }).catch((error) => {
    //             console.error("Error :: " + error)
    //             this.setState({ showSuccessMessage: false })
    //             this.setState({ hasJoinRoomFailed: true })
    //             this.setState( { isJoinRoomDisable: false })
    //             this.setState( { buttonValue: 'Join Meeting'})
    //         })
    //     } else {
    //         this.setState({ showSuccessMessage: false })
    //         this.setState({ hasJoinRoomFailed: true })
    //         this.setState( { isJoinRoomDisable: false })
    //         this.setState( { buttonValue: 'Join Meeting'})
    //     }
    // }

    render() {

        
      
        return(
           
            <div>
            <main className="main d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="card-group" style={{marginTop: "3%" , marginBottom:"3%"}}>
                                <div className="card">
                                    {/* <div className="card-header bg-primary text-light">
                                        <h3>Video Conference System</h3>
                                    </div> */}
                                    
                                    <div className="card-body">
                                        {/* {this.state.hasJoinRoomFailed &&
                                            <div className="alert alert-danger" role="alert">
                                                Invalid meeting name or password
                                            </div>
                                        } */}
                                        <p className="text-muted">
                                            <b>If you have received the invitation to join the meeting </b></p>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-home"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="Meeting name" autoComplete="meetingId"
                                                name="meetingId" id="meetingId"
                                                value={this.state.meetingId}
                                                autoFocus={this.state.meetingId}
                                                onChange={this.handleChange} />
                                        </div>
            
                                        {this.state.roomErrorMessage.length > 0 && <span  className="text-danger ml-6">{this.state.roomErrorMessage}</span>}
                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-lock"></i></span>
                                            </div>
                                            <input type="password" className="form-control" 
                                                placeholder="Password" autoComplete="current-password"
                                                name="password" 
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                />
                                        </div>
                                        {this.state.passwordErrorMessage.length > 0 && <span  className="text-danger ml-6">{this.state.passwordErrorMessage}</span>}
                                        <div className="row">
                                            <div className="col-6 mt-4">
                                                <button type="button" className="btn btn-primary px-4" 
                                                    onClick={this.joinRoomClicked} disabled={this.state.isJoinRoomDisable}>
                                                    {this.state.buttonValue}   
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


<div className="modal fade" id="participantnamepopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Enter Name</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetValues}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div className="modal-body">
      <div className="input-group">


<input type="hidden" id="roomId"/>

      <input type="text" className="form-control"
       placeholder="Enter Your Name" name="participantname" id="participantID" 
       onChange={this.handleChange}/>
      </div>
      {this.state.nameErrorMessage.length > 0 && <span  className="text-danger">{this.state.nameErrorMessage}</span>}
      </div>

      <div className="modal-footer mt-4">
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.resetValues}>Close</button>
        <button type="button" className="btn btn-primary" onClick={this.saveButtonClicked} >Submit</button>
      </div>

    </div>
  </div>
</div>

<MeetingPopup id="0" meetingId={this.state.meetingId} participantname={this.state.participantname}
isJoined={this.state.isJoinedClicked} password={this.state.password}/>
</main>
</div>

                // <div className="site-card-border-less-wrapper">
                //     {this.state.hasLoginFailed && <Alert message="Invalid email or password" type="error" showIcon />}
                //     <br />
                    // <Input 
                    //         placeholder="Email"
                    //         suffix={<UserOutlined />} 
                    //         name="email"
                    //         value={this.state.email} 
                    //         onChange={this.handleChange} 
                    //     />
                    // <br /><br />
                    // <Input.Password
                    //     placeholder="Password"
                    //     iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    //     name="password"
                    //     value={this.state.password} 
                    //     onChange={this.handleChange}
                    // />
                //     <br /><br />
                //     <Button disabled={this.state.isLoginDisable} type="primary" onClick={this.loginClicked}>{this.state.buttonValue}</Button>
                // </div>
        )
    }
}

export default JoinRoomAuthenticationPage