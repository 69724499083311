import React, { Component } from 'react'
import RegistrationService from './RegistrationSevice'
import validator from 'validator'
import $ from 'jquery';

class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            designation: '',
            hasSaveFailed: false,
            hasDuplicate : false,
            showSuccessMessage: false,
            buttonValue: 'Save',
            errors: {
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                designation: '',
              }
        }

        this.handleChange = this.handleChange.bind(this)
       // this.handleSelectChange = this.handleSelectChange.bind(this)
        this.saveClicked = this.saveClicked.bind(this)
        this.resetValues = this.resetValues.bind(this)
        this.escFunction = this.escFunction.bind(this);
    }


     validate(email,firstname,lastname,password,designation){
        let errors = this.state.errors;
        errors.email = !validator.isEmail(email) ? 'Enter valid Email' :'';
        errors.firstName = validator.isEmpty(firstname) ? 'Enter First Name.': '';
        errors.lastName = validator.isEmpty(lastname) ? 'Enter Last Name.': '';
        errors.password = validator.isEmpty(password) ? 'Enter Password.': '';
        errors.designation = validator.isEmpty(designation) ? 'Select Role.': '';
        
        if(errors.email!=='' || errors.firstName!=='' || errors.lastName!=='' || errors.password!=='' || errors.designation!==''){
            return false;  
        }else{
            return true;
        }     
    }

    handleChange(event) {
    
        this.setState({hasSaveFailed:false});
        this.setState({hasDuplicate:false});
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'email': 
              errors.email = !validator.isEmail(value) ? 'Enter valid Email' :'';
              //errors.email = validator.isEmpty(value) ? 'Enter Email.': '';
            break;
            case 'firstName': 
              errors.firstName = validator.isEmpty(value) ? 'Enter First Name.': '';
            break;
            case 'lastName': 
              errors.lastName = validator.isEmpty(value) ? 'Enter Last Name.': '';
            break;
            case 'password': 
              errors.password = validator.isEmpty(value) ? 'Enter Password.': ''; 
            break;  
            case 'designation': 
            errors.designation = validator.isEmpty(value) ? 'Select Role.': ''; 
            break; 
            default:   
            
        }
        this.setState({errors, [name]: value});
    }

    // handleSelectChange(event) {
    //     this.setState(
    //         {
    //             designation: event.target.value
    //         }
    //     )
    // }

    saveClicked() {
        let flag = this.validate(this.state.email,this.state.firstName,this.state.lastName,this.state.password,this.state.designation);
        this.setState({isButtonDisabled: true})
        if(flag){
            this.setState({buttonValue: 'Saving...'})
            RegistrationService.saveUserData(this.state)
                .then((response) => {
                  
                    if(response.data.status!=='duplicate'){
                        this.setState( {email: '' })
                        this.setState( {firstName: '' })
                        this.setState( {lastName: '' })
                        this.setState( {password: '' })
                        this.setState( {designation: '' })
                        this.setState( {hasSaveFailed: false })
                        this.setState({hasDuplicate : false})
                        this.setState( {showSuccessMessage: true })
                        this.setState( {buttonValue: 'Save' })
                        alert('user created successfully')
                        $("#registration").modal('hide');
                        window.parent.location.reload();
                        // window.location.href = "/login"
                    }else{
                        this.setState({hasDuplicate : true})
                        this.setState( {buttonValue: 'Save' })
                        alert('User is already registered with this email id')
                    }
                   
                }).catch((error) => {
                    console.error("Error:: " + error)
                    
                        if(error.response.status === 401) {
                            window.location.href = "/logout";
                        }
                    
                    this.setState( {email: '' })
                    this.setState( {firstName: '' })
                    this.setState( {lastName: '' })
                    this.setState( {password: '' })
                    this.setState( {designation: '' })
                    this.setState({ showSuccessMessage: false })
                    this.setState({ hasSaveFailed: true })
                    this.setState({buttonValue: 'Save'})
                    this.setState({hasDuplicate : false})
                    alert('Registration Failed! Please try again later or contact administrator')
                })
        }
           
    }

    escFunction(event){
        if(event.keyCode === 27) {
           this.resetValues();
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    resetValues(){
    
        this.setState( {email: '' })
        this.setState( {firstName: '' })
        this.setState( {lastName: '' })
        this.setState( {password: '' })
        this.setState({designation : ''})
        let errors = this.state.errors;
        errors.email = '';
        errors.firstName = '';
        errors.lastName = '';
        errors.password = '';
        errors.designation = '';
    }

    render() {
        const {errors} = this.state;
        // if(AuthenticationService.isUserLoggedIn()) {
        //     window.location.href = '/welcome'
        // }
            /* <Redirect to='/welcome' /> */
        return(
            <div className="modal fade" id="registration" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add User</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetValues}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                                        {/* {this.state.hasSaveFailed &&
                                            <div className="alert alert-danger" role="alert">
                                                <strong>Registration Failed! </strong> Please try agin later or contact administrator.
                                            </div>
                                        } */}
{/* 
                                        {this.state.hasDuplicate &&
                                            <div className="alert alert-danger" role="alert">
                                                <strong>Registration Failed! </strong> User is already registered with this email id .
                                            </div>
                                        } */}

                                        {/* {this.state.showSuccessMessage &&
                                            <div className="alert alert-success" role="alert">
                                                <strong>Registration success! </strong>
                                                Please go to <Link to="/login" className="alert-link">Login page</Link> and login using email and password.
                                            </div>
                                        } */}
                                        <p className="text-muted">Create new User</p>
                                        
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="icon-envelope"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="Email*"
                                                name="email" id="email"
                                                value={this.state.email}
                                                onChange={this.handleChange} />
                                        
                                        </div>

                                        {errors.email.length > 0 && <span  className="text-danger" id="emailspan">{errors.email}</span>}

                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="First Name*"
                                                name="firstName" id="firstName"
                                                value={this.state.firstName}
                                                onChange={this.handleChange} />
                                        </div>

                                        {errors.firstName.length > 0 && <span  className="text-danger" id="firstnamespan">{errors.firstName}</span>}

                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-user"></i></span>
                                            </div>
                                            <input type="text" className="form-control" 
                                                placeholder="Last Name*" 
                                                name="lastName" id="lastName"
                                                value={this.state.lastName}
                                                onChange={this.handleChange} />
                                        </div>

                                        {errors.lastName.length > 0 && <span  className="text-danger" id="lastnamespan">{errors.lastName}</span>}

                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="fa fa-tasks"></i></span>
                                            </div>
                                            <select id="designation" className="form-control" name="designation" value={this.state.designation} onChange={this.handleChange}>
                                                <option value="">--- Select Role ---</option>
                                                <option value="1">Admin</option>
                                                <option value="2">User</option>   
                                            </select>
                                        </div>

                                        {errors.designation.length > 0 && <span  className="text-danger" id="designationspan">{errors.designation}</span>}

                                        <div className="input-group mt-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-lock"></i></span>
                                            </div>
                                            <input type="password" className="form-control" 
                                                placeholder="Password*"
                                                name="password" id="currentpassword"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                onKeyPress={(event) => {
                                                    var code = event.keyCode || event.which;
                                                    if(code === 13) {
                                                        document.getElementById('btnSave').click();
                                                    } 
                                                    }}
                                                />
                                        </div>
                                        {errors.password.length > 0 && <span  className="text-danger" id="passwordspan">{errors.password}</span>}
                                        
                                     
                            <div className="modal-footer mt-4">
        <button type="button" className="btn btn-secondary"  data-dismiss="modal" onClick={this.resetValues}>Close</button>
        <button type="button" className="btn btn-primary"  id='btnSave' onClick={this.saveClicked}> {this.state.buttonValue}</button>
      </div>
    </div>
    </div>
    </div>
    </div>
  
        )
    }
}

export default Registration