import React, { Component } from 'react'

import AddRoom from '../room/addRoom'
import JoinRoom from '../room/joinRoom'
import RoomList from '../room/roomList'
import  '../room/roomList.css';
import AuthenticationService from '../authentication/AuthenticationService';


class Meetings extends Component {

    constructor(props) {
        
        super(props)
        this.state = {
            roomId: '',
            jwtToken: ''
        }
    }

    render() {
        if(!AuthenticationService.isUserLoggedIn()  ) {
            window.location.href = '/login'
          }
        return(
            <div class="container-fluid">
            <div class="card shadow mb-4 mt-3">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Your Meetings</h6>
            </div>

            <main className="main d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="card-group" style={{marginTop: "2%"}}>
                                <div className="card mb-3">

          <div class="card-body">
                                  
                                        <RoomList />
                                        <br></br>
                                        <div className="row">
                                            
                                                <div className="col-md-6">
                                                    <p> <button type="button" className="btn btn-primary meetingButton" data-toggle="modal" data-target="#addRoom">
                                                        Create new meeting
                                                    </button> </p>    
                                                </div>

                                                <div className="col-md-6">
                                                <p> <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#joinRoom">
                                                    Join other meeting
                                                </button> </p>


                                                {/* <p> <button type="button" className="btn btn-primary"  onClick={this.saveClicked}>
                                                    Start 
                                                </button> </p>

                                                <p> <button type="button" className="btn btn-primary"  onClick={this.saveClicked}>
                                                    End
                                                </button> </p> */}

                                                </div>
                                            </div>
                                    </div>
                               
                            </div>
                <AddRoom />
                <JoinRoom />
            </div>
            </div>
            </div>
            </div>
            </main>
            </div>
            </div>
        )
    }
}

export default Meetings