import { Component } from 'react';
import * as applicationConstants from '../utils/applicationConstants';
import AuthenticationService from '../authentication/AuthenticationService';
import axios from 'axios';

class RoomService extends Component {

    executeAddRoomService(roomId,password) {
        let email = AuthenticationService.getLoggedInUserName();
        let token = AuthenticationService.getSecurityToken();
        
        return axios.post(`${applicationConstants.API_URL}/roomDetails/s/usr/saveRoomDetils`, 
         {
            email,
            roomId,
            password
        }, {
            headers: {
                'Authorization': token
            },
        }
        )
    }

    executeGetRoomDetail(roomId) {
        return axios.get(`${applicationConstants.API_URL}/roomDetails/getRoomDetail/${roomId}`
        )
    }

    executeGetRoomDetailsService() {
        let email = AuthenticationService.getLoggedInUserName();
        let token = AuthenticationService.getSecurityToken();
        return axios.get(`${applicationConstants.API_URL}/roomDetails/s/usr/getRoom/${email}`
            ,{
                headers: {
                    'Authorization': token
                },
            }
        )
    }

    executeRemoveRoomService(id) {
        let token = AuthenticationService.getSecurityToken();

        return axios.delete(`${applicationConstants.API_URL}/roomDetails/s/usr/${id}`, 
        {
            headers: {
                'Authorization': token
            },
        }
        )
    }

    getRoomHistory(){
        return axios.get(`${applicationConstants.API_URL}/roomDetails/getRoomHistory`)
    }

    executeJoinRoomAuthenticationService(roomId, password) {
        return axios.post(`${applicationConstants.API_URL}/roomDetails/joinRoomAuthentication`, {
            roomId,
            password
        })
    }

    updateParticipantCount(displayName,roomId) {
        return axios.post(`${applicationConstants.API_URL}/roomDetails/updateParticipantCount`,{
            displayName,
            roomId
        })
    }

    executeGetActiveRoomDetailsService(){
        return axios.get(`https://conference.rpathos.com/nbConfPart`);
    }

    
    async executeParticipantSizeByRoomId(roomId){
        return await axios.get(`https://conference.rpathos.com/room-size?room=`+roomId);
    }

    saveRoomHistory(roomId,meetingType){
       
        return axios.post(`${applicationConstants.API_URL}/roomDetails/saveRoomHistory`, 
         {
            roomId,
            meetingType
            
        }
        )
    }

   
}

export default new RoomService()